import cn from 'classnames'
import Link from 'next/link'
import { FC, memo } from 'react'
import DateText from './DateText'
import styles from './Post.module.scss'
import Tags from './Tags'
import { PreviewPostFragment } from '../../queries/generated/blog/schema'
import { CornersImage } from '../common/CornersImage/CornersImage'
import { declOfNum } from '../../utils/declOfNum'

interface PostProps {
  className?: string
  data: PreviewPostFragment
  imageMaxWidth?: number
  sizes?: string
  showTags?: boolean
  bigTitle?: boolean
  sliceTags?: boolean
  showViews?: boolean
  showPosts?: boolean
  posts?: number
  href?: string
  priority?: boolean
}

const Post: FC<PostProps> = ({
  className,
  data,
  imageMaxWidth = 245,
  sizes,
  showTags,
  bigTitle,
  sliceTags,
  showViews,
  showPosts,
  posts,
  href,
  priority,
}) => {
  const height = Math.floor(imageMaxWidth * 1.496)

  return (
    <div className={cn(styles.post, className)}>
      {data.picture?.url && (
        <Link
          className={cn(styles.imageWrapper, styles.link)}
          href={href ? href + `/${data.slug}` : `/${data.slug}`}
        >
          <CornersImage
            className={styles.image}
            src={data.picture?.url}
            width={imageMaxWidth}
            height={height}
            withOptimize
            alt={data.title}
            sizes={sizes}
            priority={priority}
            loading={priority ? 'eager' : 'lazy'}
          />
        </Link>
      )}
      <div className={styles.row}>
        {showTags && (
          <Tags
            className={styles.tag}
            tags={sliceTags ? data?.tags.slice(0, 1) || [] : data?.tags || []}
          />
        )}
        {showPosts && posts > 0 ? (
          <span className={cn('h4', styles.posts)}>
            {posts} {declOfNum(posts, ['запись', 'записи', 'записей'])}
          </span>
        ) : (
          <DateText className={styles.date} date={data.published_at} />
        )}
      </div>
      <Link
        className={styles.link}
        href={href ? href + `/${data.slug}` : `/${data.slug}`}
      >
        <p
          className={cn(
            styles.title,
            bigTitle ? 'h3' : 'h5',
            bigTitle && styles.bigTitle
          )}
        >
          <span>{data.title}</span>
        </p>
      </Link>
    </div>
  )
}

export default memo(Post)
